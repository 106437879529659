var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      key: `key_${_vm.key}`,
      staticClass:
        "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
    },
    [
      !_vm.googleCourse
        ? _c("div", { staticClass: "kt-portlet" }, [
            _c("div", { staticClass: "kt-portlet__head" }, [
              _c("div", { staticClass: "kt-portlet__head-label" }, [
                _c("h3", { staticClass: "kt-portlet__head-title" }, [
                  _vm._v(
                    " Link " +
                      _vm._s(_vm.extCourseSectionId) +
                      " to Google Classroom "
                  ),
                ]),
              ]),
            ]),
            _c(
              "form",
              {
                staticClass: "kt-form",
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [
                _c("div", { staticClass: "kt-portlet__body" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "div",
                      {
                        staticClass: "alert alert-secondary",
                        attrs: { role: "alert" },
                      },
                      [
                        _vm._m(0),
                        _c("div", { staticClass: "alert-text" }, [
                          _vm._v(
                            " Select a google course below to link with " +
                              _vm._s(_vm.extCourseSectionId) +
                              " Once a google classroom course is selected, link the course below. Use the refresh buttons to update avaiable courses and teachers accessible through your google credentials. "
                          ),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "kt-section mb-0" }, [
                    _vm.selectedGoogleCourse
                      ? _c("div", { staticClass: "row pt-1 pb-3" }, [
                          _c(
                            "div",
                            { staticClass: "col-12 pb-3 kt-font-bold" },
                            [
                              _c("i", {
                                staticClass:
                                  "flaticon2-correct kt-font-success mr-1",
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.extCourseSectionId) +
                                  " is linked to " +
                                  _vm._s(
                                    _vm.selectedGoogleCourse.googleCourseName
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "col-3" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "align-self-center ml-3 kt-media-group mr-3",
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "course-section-badge",
                                    class: `color${Math.floor(
                                      _vm.extCourseSectionId.charCodeAt(0) % 10
                                    )}`,
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.extCourseSectionId) + " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                          _vm._m(1),
                          _c("div", { staticClass: "col-8" }, [
                            _c("h6", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.selectedGoogleCourse.googleCourseName
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("p", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.selectedGoogleCourse.googleCourseSection
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                        ])
                      : _c("div", { staticClass: "row pt-1 pb-3" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-12 pb-3 kt-font-bold kt-font-bold",
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "flaticon2-warning kt-font-danger mr-1",
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.extCourseSectionId) +
                                  " is not linked, select a google course below to link. "
                              ),
                            ]
                          ),
                        ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-6" }, [
                        _c("label", [_vm._v("Search")]),
                        _c(
                          "div",
                          { staticClass: "kt-input-icon kt-input-icon--left" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.filter,
                                  expression: "filter",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                placeholder: "Filter google courses...",
                              },
                              domProps: { value: _vm.filter },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.filter = $event.target.value
                                },
                              },
                            }),
                            _vm._m(2),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "col-6" }, [
                        _c("label", [_vm._v("Limit By Google Teacher")]),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.selectedSchoolStaffId,
                                expression: "selectedSchoolStaffId",
                              },
                            ],
                            staticClass: "form-control kt-selectpicker",
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.selectedSchoolStaffId = $event.target
                                    .multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                                function ($event) {
                                  _vm.googleCourseId = 0
                                },
                              ],
                            },
                          },
                          [
                            _c("option", { domProps: { value: 0 } }, [
                              _vm._v(" All Courses "),
                            ]),
                            _vm._l(
                              _vm.courseSection.teachers,
                              function (teacher, idx) {
                                return _c(
                                  "option",
                                  {
                                    key: `teacher_link_${idx}_${teacher.schoolStaffId}_${teacher.role}`,
                                    domProps: { value: teacher.schoolStaffId },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(teacher.lastName) +
                                        ", " +
                                        _vm._s(teacher.firstName) +
                                        " "
                                    ),
                                    teacher.role == "Teacher"
                                      ? [
                                          _vm._v(
                                            " Teacher of " +
                                              _vm._s(_vm.extCourseSectionId) +
                                              " "
                                          ),
                                        ]
                                      : [_vm._v(" - My Google Account ")],
                                  ],
                                  2
                                )
                              }
                            ),
                          ],
                          2
                        ),
                      ]),
                    ]),
                    _vm.googleCourses.length == 0 && _vm.selectedTeacher
                      ? _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "alert alert-outline-danger alert-light alert-elevate fade show",
                              },
                              [
                                _vm._m(3),
                                _c("div", { staticClass: "alert-text" }, [
                                  _vm._v(
                                    " Google courses for " +
                                      _vm._s(_vm.selectedTeacher.googleEmail) +
                                      " " +
                                      _vm._s(_vm.selectedTeacher.lastName) +
                                      ", " +
                                      _vm._s(_vm.selectedTeacher.firstName) +
                                      " were not found. This may be because the google teacher is not associated with a syncgrades account, or has an invalid google email address. "
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ])
                      : _c("div", { staticClass: "kt-widget2" }, [
                          _c(
                            "div",
                            { staticClass: "link-container" },
                            _vm._l(
                              _vm.topGoogleCourses,
                              function (course, idx) {
                                return _c(
                                  "div",
                                  {
                                    key: `course_${course.extGoogleCourseId}_${idx}`,
                                    staticClass: "kt-widget2__item",
                                    class: {
                                      "kt-widget2__item--primary":
                                        course.googleCourseId ==
                                        _vm.googleCourseId,
                                      "kt-widget2__item--warning":
                                        !(
                                          course.googleCourseId ==
                                          _vm.googleCourseId
                                        ) && course.suggest,
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "kt-widget2__checkbox ml-1 p-0 pl-4 pb-2",
                                      },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "kt-radio kt-radio--brand",
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.googleCourseId,
                                                  expression: "googleCourseId",
                                                },
                                              ],
                                              attrs: {
                                                type: "radio",
                                                name: "pick",
                                              },
                                              domProps: {
                                                value: course.googleCourseId,
                                                checked:
                                                  course.googleCourseId ==
                                                  _vm.googleCourseId,
                                                checked: _vm._q(
                                                  _vm.googleCourseId,
                                                  course.googleCourseId
                                                ),
                                              },
                                              on: {
                                                change: function ($event) {
                                                  _vm.googleCourseId =
                                                    course.googleCourseId
                                                },
                                              },
                                            }),
                                            _c("span"),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "kt-widget2__info" },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "kt-widget2__title",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                return _vm.selectCourse(
                                                  course.googleCourseId
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "code",
                                              { staticClass: "text-muted" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    course.extGoogleCourseId
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  course.googleCourseName
                                                ) +
                                                " "
                                            ),
                                            !(
                                              course.googleCourseId ==
                                              _vm.googleCourseId
                                            ) && course.suggest
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "kt-badge kt-badge--success kt-badge--inline ml-3",
                                                  },
                                                  [_vm._v(" Suggested Course ")]
                                                )
                                              : _vm._e(),
                                            course.googleCourseId ==
                                            _vm.googleCourseId
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "kt-badge kt-badge--primary kt-badge--inline ml-3",
                                                  },
                                                  [_vm._v(" Selected Link ")]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass: "kt-widget2__username",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                return _vm.selectCourse(
                                                  course.googleCourseId
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  course.googleCourseSection
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "kt-widget2__actions" },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "btn btn-clean btn-sm btn-icon btn-icon-md",
                                            attrs: {
                                              href: course.alternateLink,
                                              title: "Open In Google Classroom",
                                              target: "_blank",
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "la la-external-link",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]),
                  ]),
                ]),
                _c("div", { staticClass: "kt-portlet__foot" }, [
                  _c("div", { staticClass: "kt-form__actions" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary",
                        attrs: { type: "button", disabled: _vm.loading },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.refresh.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "la la-refresh" }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.loading
                                ? "Please wait"
                                : "Refresh Course List"
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary pull-right",
                        class: {
                          "kt-spinner kt-spinner--sm kt-spinner--light":
                            _vm.saving,
                        },
                        attrs: {
                          type: "button",
                          disabled: _vm.saving || _vm.googleCourseId == 0,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.linkCourse()
                          },
                        },
                      },
                      [
                        !_vm.saving
                          ? _c("i", { staticClass: "fa fa-save" })
                          : _vm._e(),
                        _vm._v(
                          " " +
                            _vm._s(_vm.saving ? "Linking..." : "Create Link") +
                            " "
                        ),
                      ]
                    ),
                    _vm.googleCourses.length > 0 && _vm.selectedTeacher
                      ? _c("div", { staticClass: "pull-right text-muted" }, [
                          _vm._v(
                            " There are " +
                              _vm._s(_vm.googleCourses.length) +
                              " courses associated with " +
                              _vm._s(_vm.selectedTeacher.googleEmail) +
                              ". "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]
            ),
          ])
        : _c("div", { staticClass: "kt-portlet" }, [
            _c("div", { staticClass: "kt-portlet__head" }, [
              _c("div", { staticClass: "kt-portlet__head-label" }, [
                _c("h3", { staticClass: "kt-portlet__head-title" }, [
                  _vm._v(
                    " Link " +
                      _vm._s(_vm.extCourseSectionId) +
                      " to Google Classroom "
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "kt-portlet__body" }, [
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "div",
                  {
                    staticClass: "alert alert-secondary",
                    attrs: { role: "alert" },
                  },
                  [
                    _vm._m(4),
                    _c("div", { staticClass: "alert-text" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.extCourseSectionId) +
                          " is already linked to " +
                          _vm._s(_vm.googleCourse.googleCourseName) +
                          ". Please unlink before continuing. "
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "alert-icon" }, [
      _c("i", { staticClass: "flaticon-warning kt-font-brand" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "col-1", staticStyle: { "max-width": "60px" } },
      [
        _c("span", { staticClass: "kt-media kt-media--sm" }, [
          _c(
            "div",
            {
              staticClass:
                "kt-badge kt-badge--lg kt-badge--bold kt-badge--success",
            },
            [
              _c("i", {
                staticClass: "la la-link kt-font-light",
                staticStyle: { "font-size": "1.5rem !important" },
              }),
            ]
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      { staticClass: "kt-input-icon__icon kt-input-icon__icon--left" },
      [_c("span", [_c("i", { staticClass: "la la-search" })])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "alert-icon" }, [
      _c("i", { staticClass: "flaticon-warning" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "alert-icon" }, [
      _c("i", { staticClass: "flaticon-warning kt-font-brand" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }