<template>
<div
    :key="`key_${key}`"
    class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid"
>
    <div
        v-if="!googleCourse"
        class="kt-portlet"
    >
        <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
                <h3 class="kt-portlet__head-title">
                    Link {{ extCourseSectionId }} to Google Classroom
                </h3>
            </div>
        </div>

        <form
            class="kt-form"
            @submit.prevent=""
        >
            <div class="kt-portlet__body">
                <div class="form-group">
                    <div
                        class="alert alert-secondary"
                        role="alert"
                    >
                        <div class="alert-icon">
                            <i class="flaticon-warning kt-font-brand" />
                        </div>
                        <div class="alert-text">
                            Select a google course below to link with {{ extCourseSectionId }}

                            Once a google classroom course is selected, link the course below.

                            Use the refresh buttons to update avaiable courses and teachers accessible through your
                            google credentials.
                        </div>
                    </div>
                </div>

                <div class="kt-section mb-0">
                    <div
                        v-if="selectedGoogleCourse"
                        class="row pt-1 pb-3"
                    >
                        <div class="col-12 pb-3 kt-font-bold ">
                            <i class="flaticon2-correct kt-font-success mr-1" />
                            {{ extCourseSectionId }} is linked to {{ selectedGoogleCourse.googleCourseName }}
                        </div>
                        <div class="col-3">
                            <div class="align-self-center ml-3 kt-media-group mr-3">
                                <span
                                    class="course-section-badge"
                                    :class="`color${Math.floor((extCourseSectionId.charCodeAt(0)) % 10)}`"
                                >
                                    {{ extCourseSectionId }}
                                </span>
                            </div>
                        </div>
                        <div
                            class="col-1"
                            style="max-width: 60px;"
                        >
                            <span class="kt-media kt-media--sm">
                                <div class="kt-badge kt-badge--lg kt-badge--bold kt-badge--success">
                                    <i
                                        class="la la-link kt-font-light"
                                        style="font-size: 1.5rem !important;"
                                    />
                                </div>
                            </span>
                        </div>
                        <div class="col-8">
                            <h6>
                                {{ selectedGoogleCourse.googleCourseName }}
                            </h6>
                            <p>
                                {{ selectedGoogleCourse.googleCourseSection }}
                            </p>
                        </div>
                    </div>
                    <div
                        v-else
                        class="row pt-1 pb-3"
                    >
                        <div class="col-12 pb-3 kt-font-bold kt-font-bold ">
                            <i class="flaticon2-warning kt-font-danger mr-1" />
                            {{ extCourseSectionId }} is not linked, select a google course below to link.
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-6">
                            <label>Search</label>
                            <div class="kt-input-icon kt-input-icon--left">
                                <input
                                    v-model="filter"
                                    type="text"
                                    class="form-control"
                                    placeholder="Filter google courses..."
                                >
                                <span class="kt-input-icon__icon kt-input-icon__icon--left">
                                    <span><i class="la la-search" /></span>
                                </span>
                            </div>
                        </div>
                        <div class="col-6">
                            <label>Limit By Google Teacher</label>
                            <select
                                v-model="selectedSchoolStaffId"
                                class="form-control kt-selectpicker"
                                @change="googleCourseId = 0"
                            >
                                <option :value="0">
                                    All Courses
                                </option>
                                <option
                                    v-for="(teacher, idx) in courseSection.teachers"
                                    :key="`teacher_link_${idx}_${teacher.schoolStaffId}_${teacher.role}`"
                                    :value="teacher.schoolStaffId"
                                >
                                    {{ teacher.lastName }}, {{ teacher.firstName }}
                                    <template v-if="teacher.role == 'Teacher'">
                                        Teacher of {{ extCourseSectionId }}
                                    </template>
                                    <template v-else>
                                        - My Google Account
                                    </template>
                                </option>
                            </select>
                        </div>
                    </div>

                    <div
                        v-if="googleCourses.length == 0 && selectedTeacher"
                        class="row"
                    >
                        <div class="col">
                            <div class="alert alert-outline-danger alert-light alert-elevate fade show">
                                <div class="alert-icon">
                                    <i class="flaticon-warning" />
                                </div>
                                <div class="alert-text">
                                    Google courses for {{ selectedTeacher.googleEmail }} {{ selectedTeacher.lastName }},
                                    {{ selectedTeacher.firstName }} were not found.
                                    This may be because the google teacher is not associated with a syncgrades account,
                                    or has an invalid
                                    google email address.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        v-else
                        class="kt-widget2"
                    >
                        <div class="link-container">
                            <div
                                v-for="(course, idx) in topGoogleCourses"
                                :key="`course_${course.extGoogleCourseId}_${idx}`"
                                class="kt-widget2__item"
                                :class="{
                                    'kt-widget2__item--primary': course.googleCourseId == googleCourseId,
                                    'kt-widget2__item--warning': !(course.googleCourseId == googleCourseId) && course.suggest,
                                }"
                            >
                                <div class="kt-widget2__checkbox ml-1 p-0 pl-4 pb-2">
                                    <label class="kt-radio kt-radio--brand">
                                        <input
                                            v-model="googleCourseId"
                                            type="radio"
                                            name="pick"
                                            :value="course.googleCourseId"
                                            :checked="course.googleCourseId == googleCourseId"
                                        >
                                        <span />
                                    </label>
                                </div>
                                <div class="kt-widget2__info">
                                    <a
                                        href="#"
                                        class="kt-widget2__title"
                                        @click.stop.prevent="selectCourse(course.googleCourseId)"
                                    >
                                        <code class="text-muted">{{ course.extGoogleCourseId }}</code>
                                        {{ course.googleCourseName }}
                                        <span
                                            v-if="!(course.googleCourseId == googleCourseId) && course.suggest"
                                            class="kt-badge kt-badge--success kt-badge--inline ml-3"
                                        >
                                            Suggested Course
                                        </span>
                                        <span
                                            v-if="course.googleCourseId == googleCourseId"
                                            class="kt-badge kt-badge--primary kt-badge--inline ml-3"
                                        >
                                            Selected Link
                                        </span>
                                    </a>
                                    <a
                                        href="#"
                                        class="kt-widget2__username"
                                        @click.stop.prevent="selectCourse(course.googleCourseId)"
                                    >
                                        {{ course.googleCourseSection }}
                                    </a>
                                </div>
                                <div class="kt-widget2__actions">
                                    <a
                                        :href="course.alternateLink"
                                        title="Open In Google Classroom"
                                        class="btn btn-clean btn-sm btn-icon btn-icon-md"
                                        target="_blank"
                                    >
                                        <i class="la la-external-link" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="kt-portlet__foot">
                <div class="kt-form__actions">
                    <button
                        type="button"
                        class="btn btn-secondary"
                        :disabled="loading"
                        @click.stop.prevent="refresh"
                    >
                        <i class="la la-refresh" />
                        {{ loading ? "Please wait" : "Refresh Course List" }}
                    </button>

                    <button
                        type="button"
                        class="btn btn-primary pull-right"
                        :class="{ 'kt-spinner kt-spinner--sm kt-spinner--light': saving }"
                        :disabled="saving || googleCourseId == 0"
                        @click="linkCourse()"
                    >
                        <i
                            v-if="!saving"
                            class="fa fa-save"
                        />
                        {{ saving ? "Linking..." : "Create Link" }}
                    </button>

                    <div
                        v-if="googleCourses.length > 0 && selectedTeacher"
                        class="pull-right text-muted"
                    >
                        There are {{ googleCourses.length }} courses associated with {{ selectedTeacher.googleEmail }}.
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div
        v-else
        class="kt-portlet"
    >
        <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
                <h3 class="kt-portlet__head-title">
                    Link {{ extCourseSectionId }} to Google Classroom
                </h3>
            </div>
        </div>
        <div class="kt-portlet__body">
            <div class="form-group">
                <div
                    class="alert alert-secondary"
                    role="alert"
                >
                    <div class="alert-icon">
                        <i class="flaticon-warning kt-font-brand" />
                    </div>
                    <div class="alert-text">
                        {{ extCourseSectionId }} is already linked to {{ googleCourse.googleCourseName }}.
                        Please unlink before continuing.
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex';
import Types from '../store/Types';
import portfolioMixins from '../store/mixins/portfolioMixins';
import courseMixins from '../store/mixins/courseMixins';
import teacherMixins from '../store/mixins/teacherMixins';
import userMixins from '../store/mixins/userMixins';
import googleCourseMixins from '../store/mixins/googleCourseMixins';
import * as network from '../network';

export default {
    name: 'CourseGoogleLink',
    mixins: [
        userMixins,
        courseMixins,
        portfolioMixins,
        teacherMixins,
        googleCourseMixins,
    ],
    data() {
        return {
            key: 0,
            saving: false,
            loading: false,
            refreshing: false,
            saveMessage: null,
            showHelp: false,
            filter: '',
            existingGoogleExtCourseId: null,
            courseDisplayLimit: 500,
            selectedSchoolStaffId: 0,
            googleCourseId: 0,
        };
    },
    computed: {
        ...mapState({
            externalDistrictId: (state) => state.user.school.externalDistrictId,
            deviceType: (state) => state.deviceType,
            user: (state) => state.user,
            googleAccountId: (state) => state.user.googleAccountId,
            courseSections: (state) => state.database.courseSections,
            googleTeachers: (state) => state.database.googleTeachers,
            googleCourseTeachers: (state) => state.database.googleCourseTeachers,
        }),
        googleCourse() {
            return this.$_googleCourseMixins_getGoogleCourseFromRoute();
        },
        googleCourses() {
            const { school, extCourseSectionId, isDistrictManaged } = this;
            const { selectedTeacher, googleCourseId, existingGoogleExtCourseId } = this;

            const { googleCourses } = this.$store.state.database;
            return googleCourses
                .filter((g) => {
                    if (g.googleCourseState == 'DELETED') return false; // Can't activate deleted courses so no Linking allowed
                    if (selectedTeacher) {
                        const { googleEmail } = selectedTeacher;
                        const teacher = this.googleTeachers.find((t) => t.emailAddress == googleEmail);
                        if (!teacher) return false;
                        return g.extOwnerId == teacher.googleUserId;
                    }
                    return true;
                }).map((c) => {
                    const course = { ...c }; // new pointer
                    const search = (`${c.googleCourseName}.${c.googleCourseSection || ''}.${c.googleCourseDescription || ''}` || '').toLowerCase();

                    if (isDistrictManaged) {
                        const lmsAdminPattern = [
                            `${extCourseSectionId.split('-')[0]}_${extCourseSectionId.split('-')[1]}`,
                            `${school.schoolYear} ${school.schoolTerm}`, // 2022 1
                        ];
                        course.suggest = Boolean(search.indexOf(lmsAdminPattern[0]) > -1 && search.indexOf(lmsAdminPattern[1]) > -1);
                    } else {
                        course.suggest = Boolean(c.extGoogleCourseId == existingGoogleExtCourseId);
                    }

                    course.selected = false;
                    course.search = search;

                    course.selected = googleCourseId == c.googleCourseId;
                    course.sort = (`${c.linked ? 0 : 1}_${c.suggest ? 0 : 1}_${c.googleCourseName}` || '').toLowerCase();
                    return course;
                })
                .sort((a, b) => {
                    if (a.sort < b.sort) return -1;
                    if (a.sort > b.sort) return 1;
                    return 0;
                });
        },
        school() {
            return this.$_userMixins_school;
        },
        extCourseSectionId() {
            return this.$route.params.extCourseSectionId;
        },
        courseSection() {
            return this.$_courseMixins_getCourseFromRoute();
        },
        courseSectionId() {
            return this.courseSection.courseSectionId || 0;
        },
        isDistrictManaged() {
            const { externalDistrictId } = this.$store.state.user.school;
            return Boolean(externalDistrictId.endsWith('-Google'));
        },
        totalGoogleCourses() {
            return this.googleCourses.length;
        },
        totalDisplayedGoogleCourses() {
            return this.topGoogleCourses.length;
        },
        selectedGoogleCourse() {
            const { savedGoogleCourseId } = this;
            if (!savedGoogleCourseId) return null;
            return this.$_googleCourseMixins_getGoogleCourseById(savedGoogleCourseId);
        },
        selectedTeacher() {
            const { selectedSchoolStaffId } = this;
            if (!selectedSchoolStaffId) return null;
            return this.courseSection.teachers.find((t) => t.schoolStaffId == selectedSchoolStaffId);
        },
        topGoogleCourses() {
            return this.filteredGoogleCourses.slice(0, this.courseDisplayLimit);
        },
        filteredGoogleCourses() {
            const filter = (this.filter || '').toLowerCase();
            if (!filter) return this.googleCourses;
            return this.googleCourses.filter((c) => (c.search).indexOf(filter) > -1);
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            const { showError } = this;
            const { schoolId, schoolTermId } = this.$_userMixins_school;
            if (!this.courseSection) return;
            if (!this.user.googleAccountId) return;

            const { courseSectionId } = this.courseSection;
            const params = { url: { schoolId, schoolTermId, courseSectionId } };
            const v = this;
            v.existingGoogleExtCourseId = null;

            network.google.checkCourseExistence(params, (err, results) => {
                if (err) return showError(err);
                if (results.googleCourse) {
                    v.existingGoogleExtCourseId = results.googleCourse.id;
                }
            });
        },
        linkCourse() {
            const v = this;
            if (v.saving) return;
            v.saving = true;

            const { googleCourseId, courseSectionId, showError } = this;
            const { dispatch } = this.$store;
            const { $router } = this;

            const callback = function (err) {
                if (err) {
                    v.saving = false;
                    return showError(err);
                }
                // refresh course info
                dispatch(Types.actions.REFRESH_GOOGLE_COURSE, (err2) => {
                    v.saving = false;
                    if (err2) return showError(err2);
                });

                $router.back();
            };
            dispatch(Types.actions.LINK_COURSE_SECTION_TO_GOOGLE, { googleCourseId, courseSectionId, callback });
        },
        selectCourse(googleCourseId) {
            this.googleCourseId = googleCourseId;
        },
        refresh() {
            const v = this;
            v.loading = true;
            const { showError, $store } = this;
            const { schoolId, schoolTermId } = this.school;

            // refresh available courses in google
            network.google.refreshGoogleCourseAccess({ url: { schoolId, schoolTermId } }, (err) => {
                if (err) {
                    this.key += 1;
                    v.loading = false;
                    return showError(new Error('There was an error retrieving an updated course list from google'));
                }
                network.google.listGoogleCourses({ url: { schoolId, schoolTermId } }, (err2, res) => {
                    if (err2) {
                        this.key += 1;
                        v.loading = false;
                        return showError(err2);
                    }
                    $store.commit(Types.mutations.SET_DB_GOOGLE_COURSES, res.googleCourses);
                    $store.dispatch(Types.actions.REFRESH_GOOGLE_BASE_TABLES, (err3) => {
                        this.key += 1;
                        v.loading = false;
                        if (err3) return showError(err3);
                    });
                });
            });
        },
    },
};

</script>

<style scoped>
.truncate-text {
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
}

div.link-container {
    height: 40vh;
    overflow-y: scroll;
}

div.kt-widget2__info {
    line-height: 22px;
}
</style>
